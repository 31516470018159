/* Full Screen */

.modal.full .modal-dialog {
  position: fixed;
  margin: auto;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.modal.full .modal-content {
  height: 100%;
  overflow-y: auto;
}

.modal.full .close-modal {
  position: fixed;
  top: 0;
  right: 3rem;
}

.full-modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  border: 0px;
  border-radius: none;
}

.full-page {
  background: #fff;
  height: calc(100vh - 200px);

  button {
    padding: 10px;
    color: #fff;
    background-color: #0275d8;
    border: none;
    border-radius: 3px;
    box-shadow: 0px 2px 3px 1px #a7a7a7;
    &:active {
      box-shadow: none;
    }
  }

  .sub-list {
    display: flex;
    font-size: 14px;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 5px;
    border-bottom: 1px solid #eee;

    input[type="checkbox"] {
      margin-top: 5px;
      width: 22px; /*Desired width*/
      height: 22px; /*Desired height*/
    }

    &:hover {
      background: #eee;
    }
  }

  .w-md-50 {
    @media (min-width: 992px) {
      width: 50%;
    }
  }

  .w-md-80 {
    @media (min-width: 992px) {
      width: 80%;
    }
  }
}

.display-none {
  display: none !important;
}

.progressbar {
  counter-reset: step;
}
.progressbar li {
  list-style-type: none;
  width: 25%;
  float: left;
  font-size: 12px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  color: #7d7d7d;
}
.progressbar li:before {
  width: 30px;
  height: 30px;
  content: counter(step);
  counter-increment: step;
  line-height: 30px;
  border: 2px solid #7d7d7d;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  background-color: white;
}
.progressbar li:after {
  width: 100%;
  height: 2px;
  content: '';
  position: absolute;
  background-color: #7d7d7d;
  top: 15px;
  left: -50%;
  z-index: -1;
}
.progressbar li:first-child:after {
  content: none;
}
.progressbar li.active {
  color: dodgerblue;
}
.progressbar li.active:before {
  border-color: dodgerblue;
}
.progressbar li.active + li:after {
  background-color: dodgerblue;
}

span.step {
  background: #000000;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #ffffff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 5px;
  text-align: center;
  width: 1.6em; 
}