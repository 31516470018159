/* Utilities */
.text-underline {
	text-decoration: underline !important;
}

.user-select-none {
	user-select: none !important;
}

.text-gray {
	color: #959595;
}

.text-gold {
	color: #f6ae2d;
	fill: #f6ae2d;
}

.text-brand-primary {
	color: #0e0e52;
}

.text-sm {
	font-size: 0.8rem !important;
}

.icon-danger {
	fill: var(--danger);
}

.icon-success {
	fill: var(--success);
}

.btn-w-min {
	min-width: 5rem;
}

/* Overrides */
button:disabled,
button[disabled] {
	cursor: not-allowed;
}

input:disabled {
	cursor: not-allowed;
}

.modal-dialog {
	outline: none;
}

.outline-none,
.outline-none:focus {
	outline: none !important;
	box-shadow: none !important;
}

/* Select Role Page */
.main {
	width: 100vw;
	min-height: 100vh;
	display: flex;
	user-select: none;
}

.left-pane {
	flex-basis: 50%;
	height: 100%;
	background-image: radial-gradient(rgba(17, 13, 68, 0.9), rgba(17, 13, 68, 1)),
		url("https://res.cloudinary.com/ddufn6ug6/image/upload/v1599719595/Capture_2x.png");
	background-size: cover;
	background-position: center;
	color: #ffffff;

	background: #ffffff;
	padding: 0 10rem;
}

.left-pane p {
	font-size: 0.92rem;
	text-align: center;
}

.left-pane p.display-4 {
	font-size: 1.8rem;
	color: #04e4ff;
}

.left-pane-logo {
	width: 20rem;
}

.left-pane-white {
	flex-basis: 50%;
	/*
   height: 100%;

  background-image: radial-gradient(rgba(17, 13, 68, 0.9), rgba(17, 13, 68, 1)),
    url("https://res.cloudinary.com/ddufn6ug6/image/upload/v1599719595/Capture_2x.png");
  background-size: cover;
  background-position: center;
  color: #ffffff;
  */
	background: #f7f8fd;
	padding: 0 5rem;
}

.left-pane-white p.display-4 {
	font-size: 1.8rem;
}

.left-pane-white-logo {
	width: 10rem;
}

.right-pane {
	flex-basis: 50%;
	background-color: #ffffff;
	padding: 0 5rem;
}

.right-pane .display-4 {
	font-size: 1.5rem;
}

.right-pane .signup-option {
	border: 1px solid #00000029;
	background-color: #ffffff;
	border-radius: 10px;
	padding: 1.5rem 2rem;
	color: var(--dark);
	transition: all linear 0.15s;
	min-width: 12rem;
	min-height: 12rem;
}

.right-pane .signup-option > div {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.right-pane .signup-option p {
	color: #0e0e52;
	font-weight: 600;
}

.right-pane .signup-option:hover,
.right-pane .signup-option:focus {
	border: 1px solid #3788f7;
}

.icon-wrapper {
	border-radius: 50%;
	padding: 1rem;
	height: 6rem;
	width: 6rem;
	margin-bottom: 1.2rem;
	display: flex;
	align-items: center;
	justify-content: center;
}

.icon-wrapper svg {
	fill: #f6ae2d;
}

.icon-wrapper ~ p {
	font-size: 0.85rem;
}

/* Register */
.auth .right-pane {
	padding: 0 12rem;
}

.legal-notice {
	font-size: 0.7rem;
	text-align: center;
}

.input-group-append {
	cursor: pointer;
}

.input-group input[type="password"] {
	border-color: #ced4da;
	border-right-color: transparent;
}

.input-group-text {
	background-color: #ffffff !important;
	border-left: none !important;
}

.react-tel-input .form-control.phone-input-control {
	height: calc(1.5em + 0.94rem + 2px);
	font-size: 0.8125rem;
	line-height: 1.5;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

.has-error input.form-control,
.has-error .input-group-text,
.has-error .flag-dropdown {
	border-bottom: 1px solid var(--danger) !important;
}

.has-error-top .form-control,
.has-error-top .input-group-text,
.has-error-top .flag-dropdown {
	border-top: 2px solid var(--danger) !important;
}

.has-error-top label {
	color: var(--danger) !important;
}

.form-validation-message {
	font-size: 0.7rem;
	color: var(--danger);
	text-align: right;
	animation: wiggle 0.5s;
}

@keyframes wiggle {
	10%,
	90% {
		transform: translate3d(-1px, 0, 0);
	}

	20%,
	80% {
		transform: translate3d(1px, 0, 0);
	}

	30%,
	50%,
	70% {
		transform: translate3d(-2px, 0, 0);
	}

	40%,
	60% {
		transform: translate3d(2px, 0, 0);
	}
}

/* VERIFY ACCOUNT */
.otp-input > div {
	justify-content: space-between;
	width: 65%;
}

.otp-input input {
	width: 2em !important;
	height: 2em;
	font-size: 1rem;
}

/* ACCOUNT  */
.account-navmenu-item {
	color: #666692;
	padding: 0.25rem 0.6rem;
	margin-bottom: 3px;
}

.account-navmenu-active-item {
	font-weight: bold;
	color: #0e0e52;
	background: rgba(102, 102, 146, 0.2);
	border-radius: 6px;
}

.account-page .card .card-title {
	margin-bottom: .8rem;
}

.account-page .card .card-title .display-4 {
	font-size: 1.2rem;
	font-weight: 500;
	color: #0e0e52;
}

.account-page .card .card-title button {
	min-width: 6rem;
}

.account-page .form-group label {
	color: #0e0e52;
}

/* IMAGE UPLOADER */
.image-uploader {
	height: 7.5rem;
	width: 7.5rem;
	background-color: #ededf2;
	background-size: cover;
	background-repeat: no-repeat;
	border-radius: 50%;
	position: relative;
	border: 3px solid #ffffff;
	box-shadow: 0 2px 1px rgba(0, 0, 0, 0.03);
	display: flex;
	align-items: center;
	justify-content: center;
}

.image-uploader i {
	font-size: 1.1rem;
	color: #8c8e97;
	font-weight: 300;
}

.image-uploader .uploader-icon-container {
	width: 1.8rem;
	height: 1.8rem;
	padding: 3px;
	background-color: #ffffff;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	bottom: 3px;
	right: 3px;
}

.image-uploader input[type="file"] {
	width: 7.5rem;
	height: 7.5rem;
	opacity: 0;
	cursor: pointer;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

/* LOADER */
.loader {
	border: 2px solid #bac1e6;
	border-radius: 50%;
	border-top: 2px solid #f7f8fd;
	width: 2rem;
	height: 2rem;
	-webkit-animation: spin 0.7s linear infinite; /* Safari */
	animation: spin 0.7s linear infinite;
}

.loader-image {
	width: 10rem;
}

/* Safari */
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.logout-profile-user {
	height: 5.5rem;
	width: 5.5rem;
	background-color: #f6f6f6;
}

.logout-confirm-text {
	color: #0e0e52;
}

.client-logo {
	box-shadow: 0px 2px 6px 2px #ccc;
	cursor: pointer;
	width: 222px;
	text-align: center;
}

.client-logo:hover {
	box-shadow: 0px 2px 10px 3px #3788f7;
}

.client-price {
	box-shadow: 0px 2px 6px 2px #ccc;
	cursor: pointer;
	width: 120px;
	text-align: center;
}
.client-price-disabled {
	box-shadow: 0px 1px 0px 1px #ccc;
	width: 170px;
	text-align: center;
}

.client-price:hover {
	box-shadow: 0px 2px 10px 3px #3788f7;
}

.text-point-nine {
	font-size: 0.9em;
}

.react-switch-checkbox {
	height: 0;
	width: 0;
	visibility: hidden;
}

.react-switch-label {
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	width: 40px;
	height: 20px;
	background: grey;
	border-radius: 30px;
	position: relative;
	transition: background-color 0.2s;
}

.react-switch-label .react-switch-button {
	content: "";
	position: absolute;
	top: 2px;
	left: 2px;
	width: 15px;
	height: 15px;
	border-radius: 15px;
	transition: 0.2s;
	background: #fff;
	box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
	left: calc(100% - 2px);
	transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
	width: 40px;
}

.custom-map-modal .modal-dialog {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
}
.custom-map-modal .modal-content {
	height: auto;
	min-height: 100%;
	border-radius: 0;
}

.modal.fade:not(.in).left .modal-dialog {
	-webkit-transform: translate3d(-25%, 0, 0);
	transform: translate3d(-25%, 0, 0);
}
.modal.fade:not(.in).right .modal-dialog {
	-webkit-transform: translate3d(25%, 0, 0);
	transform: translate3d(25%, 0, 0);
}
.modal.fade:not(.in).bottom .modal-dialog {
	-webkit-transform: translate3d(0, 25%, 0);
	transform: translate3d(0, 25%, 0);
}

/* Alternative Angles */
.modal.fade:not(.in).top-left .modal-dialog {
	-webkit-transform: translate3d(-25%, -25%, 0);
	transform: translate3d(-25%, -25%, 0);
}
.modal.fade:not(.in).top-right .modal-dialog {
	-webkit-transform: translate3d(25%, -25%, 0);
	transform: translate3d(25%, -25%, 0);
}
.modal.fade:not(.in).bottom-left .modal-dialog {
	-webkit-transform: translate3d(-25%, 25%, 0);
	transform: translate3d(-25%, 25%, 0);
}
.modal.fade:not(.in).bottom-right .modal-dialog {
	-webkit-transform: translate3d(25%, 25%, 0);
	transform: translate3d(25%, 25%, 0);
}

.nav {
	margin-bottom: 15px;
	text-align: center;
}

.dot {
	color: black;
	cursor: pointer;
	font-size: 36px;
	line-height: 1;
	margin: 0 15px;
	opacity: 0.4;
	text-shadow: none;
	transition: opacity 1s ease, text-shadow 1s ease;
	will-change: opacity, text-shadow;
}

/* .active {
	color: var(--blue);
	opacity: 1;
	text-shadow: 0 0px 8px;
} */

.animated {
	animation-duration: 0.5s;
	animation-fill-mode: backwards;
	transform-style: preserve-3d;
}

/** intro */
@keyframes intro {
	from {
		opacity: 0;
		transform: perspective(500px) translate3d(0, 0, -50px);
	}

	to {
		opacity: 1;
		transform: none;
	}
}

.intro {
	animation: intro 1s ease-out;
}

/** enterRight */
@keyframes enterRight {
	from {
		opacity: 0;
		transform: perspective(500px) translate3d(20%, 0, 0);
	}

	to {
		opacity: 1;
		transform: none;
	}
}

.enterRight {
	animation-name: enterRight;
}

/** enterLeft */
@keyframes enterLeft {
	from {
		opacity: 0;
		transform: perspective(500px) translate3d(-20%, 0, 0);
	}

	to {
		opacity: 1;
		transform: none;
	}
}

.enterLeft {
	animation-name: enterLeft;
}

/** exitRight */
@keyframes exitRight {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
		transform: perspective(500px) translate3d(100%, 0, -100px);
	}
}

.exitRight {
	animation-name: exitRight;
}

/** exitLeft */
@keyframes exitLeft {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
		transform: perspective(500px) translate3d(-100%, 0, -100px);
	}
}

.exitLeft {
	animation-name: exitLeft;
}
