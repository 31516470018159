
//
// accordion.scss
//

.custom-accordion {

    .accordion-list {
        display: flex;
        border-radius: 7px;
        background-color: $gray-300;
        padding: 12px 20px;
        color: $body-color;
        font-weight: $font-weight-semibold;
        align-items: center;
        justify-content: space-between;
        &.collapsed {
            i.accor-plus-icon {
                &:before {
                    content: "\F415";
                }
            }
        }

        .accor-plus-icon{
            display: inline-block;
            font-size: 16px;
            height: 24px;
            width: 24px;
            line-height: 22px;
            background-color: $card-bg;
            text-align: center;
            border-radius: 50%;
        }
    }

    .card-body {
        color: $text-muted;
    }
}



.accordion {
    border: 1px solid #DADFF6;
    border-radius: 3px;
  }
  
  .accordion__item + .accordion__item {
    border-top: 1px solid #DADFF6;
  }
  
  .accordion__button {
    background-color: #FFFFFF;
    font-size: 16px;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;
  }
  
  .accordion__button:hover {
    background-color: darken(#F7F8FD, 1%);
  }
  .accordion__button:focus {
    outline:0;
  }
  
  
  .accordion__button:before {
    display: inline-block;
    content: '';
    height: 10px;
    width: 10px;
    margin-right: 12px;
    border-bottom: 1px solid currentColor;
    border-right: 1px solid currentColor;
    transform: rotate(-45deg);
  }
  
  .accordion__button[aria-expanded='true']::before,
  .accordion__button[aria-selected='true']::before {
    transform: rotate(45deg);
  }
  
  [hidden] {
    display: none;
  }
  
  .accordion__panel {
    padding: 20px;
    animation: fadein 0.35s ease-in;
  }
  
  /* -------------------------------------------------- */
  /* ---------------- Animation part ------------------ */
  /* -------------------------------------------------- */
  
  @keyframes fadein {
    0% {
        opacity: 0;
    }
  
    100% {
        opacity: 1;
    }
  }