@media (max-width: 1200px) {
  .main .left-pane-white {
    display: none;
  }

  .main .right-pane {
    flex-basis: 100%;
  }

  .right-pane-logo {
    display: block !important;
    width: 10rem;
    margin-bottom: 6rem;
  }

  .right-pane-logo ~ .btn-link {
    display: none;
  }
}

@media (max-width: 576px) {
  .auth .right-pane {
    padding: 0 2rem;
  }

  .auth.register-page .right-pane {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .signup-options-container {
    flex-direction: column;
  }

  .signup-options-container a.mr-3 {
    margin-right: 0 !important;
    margin-bottom: 2rem;
  }

  .signup-options-container a.ml-3 {
    margin-left: 0 !important;
  }
}
