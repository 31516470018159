// 
// _table.scss
// 

.table {
  border-top:0px;
  thead{
  th {
      font-weight: $font-weight-bold;
      text-transform: uppercase;
      border-bottom: 1px;
      border:0px;
  }
}
}

//Table centered
.table-centered {
  td,th {
      vertical-align: middle !important;
  }
}

.table-nowrap {
  th, td {
    white-space: nowrap;
  }
}